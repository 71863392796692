<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->

    <b-navbar toggleable="md" type="dark" variant="info">
      <b-navbar-brand   to="/">PhotoLine</b-navbar-brand> 
      <!-- <b-navbar-brand v-if="username" to="/albums">PhotoLine</b-navbar-brand>  -->
    <small>Store Secure & Share pictures</small>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item class="ml-auto" v-if="username"><b-button variant="primary" to="/albums">Albums</b-button></b-nav-item>
          <b-nav-item class="ml-auto" v-if="!username"><b-button variant="primary" to="/login">Login</b-button></b-nav-item>
          <b-nav-item-dropdown right v-if="username">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ username }}</em>
            </template>
            <b-dropdown-item to="/addalbum">Add Album</b-dropdown-item>
            <b-dropdown-item to="/addlocation">Add Location</b-dropdown-item>
            <b-dropdown-item to="/logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <!-- <b-nav class="m-1">
      <b-navbar-brand class="ml-3" to="/">Photo Line</b-navbar-brand>
      <b-nav-item class="ml-auto" v-if="username"><b-link to="/addalbum">Albums</b-link></b-nav-item>
      <b-nav-item class="ml-auto" v-if="username"><b-link to="/addlocation">Locations</b-link></b-nav-item>
      <b-nav-item class="ml-auto" v-if="!username"><b-link to="/login">Login</b-link></b-nav-item>
      <b-nav-item class="ml-auto" v-if="username" >User: {{username}}</b-nav-item>
    </b-nav> -->

    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  props: {
    msg: String,
  },
  data: function() {
    return {
      items: [],
      location: "",
      username: null,
      password: null,
      processing: false,
      pictureLocationOptions: [],
      message: null,
      messageColor: "success",
      messageShow: false,
      username: null,
      token: null,
    };
  },
  created: function() {
    this.username = localStorage.getItem("username");
  },

  methods: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

 
</style>
